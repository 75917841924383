import React from "react"
import { Link } from "gatsby"

/*
 * This component is built using `gatsby-image` to automatically serve optimized
 * images with lazy loading and reduced file sizes. The image is loaded using a
 * `useStaticQuery`, which allows us to load the image from directly within this
 * component, rather than having to pass the image data down from pages.
 *
 * For more information, see the docs:
 * - `gatsby-image`: https://gatsby.dev/gatsby-image
 * - `useStaticQuery`: https://www.gatsbyjs.org/docs/use-static-query/
 */

const Footer = () => {
  return (
    <footer>
  <div className="container content has-text-centered">
        <p>&copy; {new Date().getFullYear()} East Montgomery County Municipal Utility District No. 3<br />
        <strong><Link to={`/legal-notices-and-disclaimers`}>Legal Notices & Disclaimers</Link></strong></p>
      <p>Website provided by <a target="_blank" rel="noopener noreferrer" href="https://www.touchstonedistrictservices.com/">Touchstone</a></p>
  </div>
  </footer>
)}

export default Footer
